/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';
import Rails from '@rails/ujs';

declare global {
  interface Window {
    Stimulus: Application;
  }
}

Rails.start();
const application = (window.Stimulus = Application.start());
const warn = application.logger.warn;
const error = application.logger.error;
application.logger = {
  ...application.logger,
  error: (message, ...args) => {
    // @ts-expect-error Cannot find name 'Rollbar'
    Rollbar.error(message, ...args);
    error(message, ...args);
  },
  warn: function (message, object) {
    // @ts-expect-error Cannot find name 'Rollbar'
    Rollbar.warn(message, object);
    warn(message, object);
  },
};
const context = require.context('../controllers', true, /\.ts$/);
application.load(definitionsFromContext(context));
