import { Controller } from '@hotwired/stimulus';
import Autocomplete from '@trevoreyre/autocomplete-js';

import '@trevoreyre/autocomplete-js/dist/style.css';

export default class PlaceForm extends Controller {
  /*
    this controller is for controlling the place form
  */

  saveAndRedirectButtonTarget: HTMLButtonElement;
  saveButtonTarget: HTMLButtonElement;
  redirectToInputTarget: HTMLInputElement;
  descriptionInputTarget: HTMLTextAreaElement;
  characterCountTarget: HTMLSpanElement;
  hasCharacterCountTarget: boolean;
  autocompleteTarget: HTMLInputElement;
  hasAutocompleteTarget: boolean;
  googleErrorsTarget: HTMLDivElement;
  streetInputTarget: HTMLInputElement;
  cityInputTarget: HTMLInputElement;
  stateInputTarget: HTMLInputElement;
  zipInputTarget: HTMLInputElement;
  phoneInputTarget: HTMLInputElement;
  urlInputTarget: HTMLInputElement;
  latInputTarget: HTMLInputElement;
  longInputTarget: HTMLInputElement;
  googleIdInputTarget: HTMLInputElement;
  hasGoogleIdInputTarget: boolean;
  openingHoursTarget: HTMLDivElement;
  // @ts-expect-error 'element' is defined as an accessor in class 'Controller', but is overridden here in 'default' as an instance property.
  element: HTMLFormElement;
  autocomplete: Autocomplete;

  static targets = [
    'saveAndRedirectButton',
    'saveButton',
    'redirectToInput',
    'descriptionInput',
    'characterCount',
    'autocomplete',
    'googleErrors',
    'streetInput',
    'cityInput',
    'stateInput',
    'zipInput',
    'phoneInput',
    'urlInput',
    'latInput',
    'longInput',
    'googleIdInput',
    'openingHours',
  ];

  static states = {
    Alabama: 'AL',
    Alaska: 'AK',
    Arizona: 'AZ',
    Arkansas: 'AR',
    California: 'CA',
    Colorado: 'CO',
    Connecticut: 'CT',
    Delaware: 'DE',
    'District of Columbia': 'DC',
    Florida: 'FL',
    Georgia: 'GA',
    Hawaii: 'HI',
    Idaho: 'ID',
    Illinois: 'IL',
    Indiana: 'IN',
    Iowa: 'IA',
    Kansas: 'KS',
    Kentucky: 'KY',
    Louisiana: 'LA',
    Maine: 'ME',
    Maryland: 'MD',
    Massachusetts: 'MA',
    Michigan: 'MI',
    Minnesota: 'MN',
    Mississippi: 'MS',
    Missouri: 'MO',
    Montana: 'MT',
    Nebraska: 'NE',
    Nevada: 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    Ohio: 'OH',
    Oklahoma: 'OK',
    Oregon: 'OR',
    Pennsylvania: 'PA',
    'Puerto Rico': 'PR',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    Tennessee: 'TN',
    Texas: 'TX',
    Utah: 'UT',
    Vermont: 'VT',
    Virginia: 'VA',
    Washington: 'WA',
    'West Virginia': 'WV',
    Wisconsin: 'WI',
    Wyoming: 'WY',
  };

  connect() {
    this.calculateDescriptionLength({
      target: this.descriptionInputTarget,
    });

    if (this.hasAutocompleteTarget) {
      this.autocomplete = new Autocomplete(this.autocompleteTarget, {
        search: this.searchGooglePlaces.bind(this),
        renderResult: this.renderGooglePlaceResult.bind(this),
        getResultValue: (result) => result.value,
        onSubmit: this.fetchPlace.bind(this),
        debounceTime: 150,
      });
    }
  }

  disconnect() {
    this.autocomplete.destroy();
  }

  saveAndRedirect() {
    this.redirectToInputTarget.value = 'new_experience';
    this.saveAndRedirectButtonTarget.textContent = 'Saving, one moment...';
    this.disableSubmitButtons();
    this.element.submit();
  }

  save() {
    this.redirectToInputTarget.value = '';
    this.saveButtonTarget.textContent = 'Saving, one moment...';
    this.disableSubmitButtons();
    this.element.submit();
  }

  disableSubmitButtons() {
    this.saveAndRedirectButtonTarget.setAttribute('disabled', 'disabled');
    this.saveButtonTarget.setAttribute('disabled', 'disabled');
  }

  calculateDescriptionLength(e) {
    // paying customers do not have character count checks
    if (!this.hasCharacterCountTarget) return;

    const description = e.target;
    const charactersLeft = 1000 - description.value.length;
    this.characterCountTarget.textContent = charactersLeft.toString();
    if (charactersLeft < 0) {
      this.characterCountTarget.classList.add('text-danger');
    } else {
      this.characterCountTarget.classList.remove('text-danger');
    }
  }

  readImage(e) {
    const input = e.target;
    const reader = new FileReader();

    reader.onload = (e) => {
      const image = document.createElement('img');
      image.setAttribute('src', e.target.result.toString());

      const nextElement = input.nextElementSibling;
      if (nextElement && nextElement.nodeName === 'IMG') {
        nextElement.remove();
      }

      input.after(image);
    };

    reader.readAsDataURL(input.files[0]);
  }

  // searches and returns results from Google places for autocomplete dropdown
  async searchGooglePlaces(query) {
    const csrfMeta = document.querySelector('meta[name="csrf-token"]');

    if (!csrfMeta) return;

    const csrfToken = csrfMeta.getAttribute('content');

    const response = await fetch(`/api/google/search?q=${query}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
    });
    const data = await response.json();

    return data.map((item) => ({
      value: item.name,
      id: item.place_id,
    }));
  }

  // renders result from Google places in autocomplete dropdown
  renderGooglePlaceResult({ value }, props) {
    return `
      <li ${props}>
        ${value}
      </li>
    `;
  }

  // fetches place selected from the Google places autocomplete dropdown
  async fetchPlace({ id: placeId }) {
    const errorMessage =
      "<div class='flash alert alert-danger'><button class='close' data-dismiss='alert'>&times;</button>There was an error retrieving the place details.</div>";

    try {
      const response = await fetch(`/api/google/details?placeid=${placeId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const place = await response.json();

      this.populateFormWith(place);
    } catch (error) {
      // @ts-ignore
      Rollbar.error('Failed to get google places autocomplete from place edit page', error);
      this.googleErrorsTarget.innerHTML = errorMessage;
    }
  }

  addSuccesValidation(element) {
    const input = element.closest('.form-group input');
    input.classList.add('is-valid');
  }

  populateFormWith(place) {
    const number = place.street_number;

    let street;
    if (number) {
      street = `${place.street_number} ${place.street}`;
    } else {
      street = place.street;
    }

    if (street) {
      this.streetInputTarget.value = street;
      this.addSuccesValidation(this.streetInputTarget);
    }

    if (place.city) {
      this.cityInputTarget.value = place.city;
      this.addSuccesValidation(this.cityInputTarget);
    }

    if (place.region) {
      this.stateInputTarget.value = PlaceForm.states[place.region];
      this.addSuccesValidation(this.stateInputTarget);
    }

    if (place.postal_code) {
      this.zipInputTarget.value = place.postal_code;
      this.addSuccesValidation(this.zipInputTarget);
    }

    if (place.international_phone_number) {
      this.phoneInputTarget.value = place.international_phone_number;
      this.addSuccesValidation(this.phoneInputTarget);
    }

    if (place.website) {
      this.urlInputTarget.value = place.website;
      this.addSuccesValidation(this.urlInputTarget);
    }

    if (place.lat) {
      this.latInputTarget.value = place.lat;
      this.addSuccesValidation(this.latInputTarget);
    }

    if (place.lng) {
      this.longInputTarget.value = place.lng;
      this.addSuccesValidation(this.longInputTarget);
    }

    if (place.place_id && this.hasGoogleIdInputTarget) {
      this.googleIdInputTarget.value = place.place_id;
      this.addSuccesValidation(this.googleIdInputTarget);
    }

    if (place.opening_hours && place.opening_hours.periods) {
      place.opening_hours.periods.forEach((day) => {
        if (day.open && day.close) {
          const openInput: HTMLInputElement = this.openingHoursTarget.querySelector(
            `#opening_hours_open_${day.open.day}`
          );
          openInput.value = day.open.time;
          this.addSuccesValidation(openInput);

          const closeInput: HTMLInputElement = this.openingHoursTarget.querySelector(
            `#opening_hours_closed_${day.close.day}`
          );
          closeInput.value = day.close.time;
          this.addSuccesValidation(closeInput);
        }
      });
    }
  }
}
