import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  /*
    this controller is for expanding content, ex: 'see more' button
  */

  contentTarget: HTMLElement;

  static targets = ['content'];

  reveal(e) {
    this.contentTarget.classList.remove('closed');
    this.contentTarget.style.maxHeight = 'none';

    const toggleLink = e.target;
    toggleLink.remove();
  }
}
