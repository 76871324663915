import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  /*
    this controller is for linking places and experiences via
    creating an offering
  */
  placesTarget: HTMLSelectElement;
  experiencesTarget: HTMLSelectElement;
  submitButtonTarget: HTMLButtonElement;
  experienceSectionTarget: HTMLDivElement;

  static targets = ['places', 'experiences', 'submitButton', 'experienceSection'];

  connect() {
    this.checkExperiences();
  }

  get selectedPlaceId(): number {
    // gets the id of the currently selected place
    const options = Array.prototype.slice.call(this.placesTarget.options);
    const selectedOption = options.find((option) => option.selected);
    return parseInt(selectedOption.dataset.id, 10);
  }

  get experienceOptions(): HTMLOptionElement[] {
    // gets all the HTMLOptionElements for experiences
    return Array.prototype.slice.call(this.experiencesTarget.options);
  }

  checkExperiences() {
    const currentPlaceId = this.selectedPlaceId;
    const experienceOptions = this.experienceOptions;

    const totalDisabled = experienceOptions.reduce((total, experienceOption) => {
      // grab stored places from the experience option
      const experiencePlaces = JSON.parse(experienceOption.dataset.places);
      // check if the currently selected place is already linked to the experience
      const hasPlace: boolean = experiencePlaces.some((place) => place.id === currentPlaceId);

      if (hasPlace) {
        experienceOption.setAttribute('disabled', 'disabled');
        return total + 1;
      } else {
        experienceOption.removeAttribute('disabled');
        return total;
      }
    }, 0);

    if (totalDisabled === experienceOptions.length) {
      this.submitButtonTarget.setAttribute('disabled', 'disabled');
      this.experienceSectionTarget.classList.add('has-warning');
    } else {
      this.submitButtonTarget.removeAttribute('disabled');
      this.experienceSectionTarget.classList.remove('has-warning');
    }
  }
}
