import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  /*
    Verifies the 2fa code when setting up 2fa in the wizard
  */
  formTarget: HTMLFormElement;
  nextTarget: HTMLAnchorElement;
  formGroupTarget: HTMLElement;

  srText: HTMLSpanElement;

  static targets = ['form', 'next', 'formGroup'];

  connect() {
    this.nextTarget.classList.add('disabled');
  }

  async submit(event) {
    event.preventDefault();

    const submitButton: HTMLInputElement = this.formTarget.querySelector("input[type='submit']");
    submitButton.disabled = true;

    const formData = new FormData(this.formTarget);
    // @ts-expect-error Type 'FormData' is missing the following properties from type 'URLSearchParams': sort, entries, keys, values, [Symbol.iterator]
    const data = new URLSearchParams(formData);
    const response = await fetch('/users/two-factor-auth/verify', {
      method: 'PUT',
      body: data,
    });

    const input = this.formGroupTarget.querySelector('input');
    if (!this.srText) {
      this.srText = document.createElement('span');
      this.srText.id = 'verifyStatus';
      this.srText.setAttribute('class', 'sr-only');

      this.formGroupTarget.append(this.srText);
      input.setAttribute('aria-describedby', this.srText.id);
    }

    if (response.ok) {
      this.nextTarget.classList.remove('disabled');
      input.classList.add('is-valid');
      input.classList.remove('is-invalid');
      this.srText.textContent = 'success';
    } else {
      input.classList.remove('is-valid');
      input.classList.add('is-invalid');
      this.srText.textContent = 'error';
    }

    submitButton.disabled = false;
  }
}
