import { Controller } from '@hotwired/stimulus';
import { CreditCard } from './credit_card_controller';

export default class extends Controller {
  /*
    this controller is used for the registration form, where you must
    accept the terms and conditions before signing up
  */
  submitTarget: HTMLButtonElement;
  creditCardFormTarget: CreditCard;
  hasCreditCardFormTarget: boolean;
  planTargets: HTMLInputElement[];
  acceptTermsTarget: HTMLInputElement;
  // @ts-expect-error 'element' is defined as an accessor in class 'Controller', but is overridden here in 'default' as an instance property.
  element: HTMLFormElement;
  stripePlan: string;

  static targets = ['submit', 'creditCardForm', 'plan', 'acceptTerms'];

  connect() {
    // store initially set stripe plan
    this.planTargets.forEach((plan) => this.setStripePlan(plan));

    if (this.acceptTermsTarget.checked) {
      this.submitTarget.removeAttribute('disabled');
    }
  }

  async submit(event) {
    if (this.stripePlan === 'free' || !this.hasCreditCardFormTarget) {
      return true;
    }

    event.preventDefault();
    this.disableSubmitButton();

    const stripeToken = await this.creditCardFormTarget.processCard();

    if (stripeToken) {
      // succeeded
      this.element.submit();
    } else {
      // failed
      this.enableSubmitButton();
    }
  }

  setStripePlan(eventOrTarget) {
    const target = eventOrTarget.target ? eventOrTarget.target : eventOrTarget;
    const plan = target.value;
    const isSelected = target.checked;
    if (isSelected) {
      this.stripePlan = plan;
    }
  }

  enableSubmitButton() {
    this.submitTarget.classList.add('btn-primary');
    this.submitTarget.classList.remove('btn-gray');
    this.submitTarget.removeAttribute('disabled');
  }

  disableSubmitButton() {
    this.submitTarget.classList.add('btn-gray');
    this.submitTarget.setAttribute('disabled', 'disabled');
  }

  toggleAcceptTerms(event) {
    const accepted = event.target.checked;
    if (accepted) {
      this.enableSubmitButton();
    } else {
      this.disableSubmitButton();
    }
  }

  togglePaymentForm(event) {
    const freeOptionSelected = event.target.value === 'free' && event.target.checked;
    this.creditCardFormTarget.hidden = freeOptionSelected;
  }
}
