import { Controller } from '@hotwired/stimulus';

const TRANSITION_STYLE = 'left 300ms ease-in-out';
export default class extends Controller {
  /*
    this controller is for creating a carousel of any content
  */

  contentTarget: HTMLElement;
  itemTargets: HTMLElement[];
  pageValue: number = 1;

  static targets = ['content', 'item'];

  connect() {
    this.contentTarget.style.transition = TRANSITION_STYLE;

    this.contentTarget.addEventListener('transitionend', this.resetItems.bind(this));
    this.page = this.pageValue;
  }

  disconnect() {
    this.contentTarget.removeEventListener('transitionend', this.resetItems.bind(this));
  }

  next() {
    this.page += 1;
  }

  previous() {
    this.page -= 1;
  }

  resetItems() {
    const currentPage = this.page;
    let newPage = currentPage;
    if (currentPage === this.pageCount + 1) {
      // going back to the beginning
      newPage = 1;
    } else if (currentPage === 0) {
      newPage = this.pageCount;
    }

    if (newPage !== currentPage) {
      // pages changed, let's move them around
      this.contentTarget.style.transition = ''; // turn off animation
      window.requestAnimationFrame(() => {
        this.page = newPage;
        window.requestAnimationFrame(() => {
          this.contentTarget.style.transition = TRANSITION_STYLE; // turn on animation again
        });
      });
    }
  }

  set page(value) {
    this.pageValue = value;

    const percent = this.pageValue * -100;
    this.contentTarget.style.left = `${percent}%`;

    this.itemTargets.forEach((item, index) => {
      item.setAttribute('aria-hidden', (this.pageValue !== index).toString());
    });
  }

  get page() {
    return this.pageValue;
  }

  get pageCount() {
    return this.itemTargets.length - 2; // -2 since we duplicate the first and last page
  }
}
