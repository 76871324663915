import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  /*
    this controller is for hooking nested forms (ala cocoon)
  */

  nestedAssociationsTarget: HTMLDivElement;
  templateTarget: HTMLTemplateElement;
  wrapperClass: string;

  static targets = ['nestedAssociations', 'template'];

  connect() {
    this.wrapperClass = this.data.get('wrapperClass') || 'nested-fields';
  }

  addAssociation(e) {
    e.preventDefault();

    const event = new CustomEvent('nested-form:before-insert', { cancelable: true });
    const cancelled = !this.element.dispatchEvent(event);

    if (cancelled) return;

    const content = this.templateTarget.innerHTML
      .replace(/NEW_RECORD/g, Date.now().toString())
      .replace(/RANDOM_VALUE/g, Date.now().toString());
    this.nestedAssociationsTarget.insertAdjacentHTML('beforeend', content);

    this.element.dispatchEvent(new CustomEvent('nested-form:after-insert'));
  }

  removeAssociation(e) {
    e.preventDefault();

    const event = new CustomEvent('nested-form:before-remove', { cancelable: true });
    const cancelled = !this.element.dispatchEvent(event);

    if (cancelled) return;

    const wrapper = e.target.closest(`.${this.wrapperClass}`);

    // New records are simply removed from the page
    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove();

      // Existing records are hidden and flagged for deletion
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1;
      wrapper.hidden = true;
    }

    this.element.dispatchEvent(new CustomEvent('nested-form:after-remove'));
  }
}
