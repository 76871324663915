import { Controller } from '@hotwired/stimulus';

const getDate = (node: HTMLElement) => Date.parse(node.dataset.date);
const compare = (nodeA: HTMLElement, nodeB: HTMLElement, columnName: string, direction: 'asc' | 'desc') => {
  const a = nodeA.dataset[columnName];
  const b = nodeB.dataset[columnName];

  let result = a.localeCompare(b);

  if (direction === 'desc') {
    result *= -1;
  }

  return result;
};

export default class extends Controller {
  /*
    this controller is used for sorting rows in a table
  */
  rowTargets: HTMLElement[];
  dateSortDirection = 'desc';

  static targets = ['row'];

  sortByDate() {
    // toggle direction
    this.dateSortDirection = this.dateSortDirection === 'desc' ? 'asc' : 'desc';

    const tableBody = this.element.querySelector('tbody');
    const rows = [...this.rowTargets];
    // remove all rows from the DOM
    rows.forEach((row) => row.remove());

    rows
      .sort((a, b) => {
        if (this.dateSortDirection == 'asc') {
          return getDate(a) - getDate(b);
        } else {
          return getDate(b) - getDate(a);
        }
      })
      .forEach((row) => tableBody.appendChild(row)); // append back to DOM
  }

  sortByTextColumn(event) {
    const columnName = event.params.column;

    // toggle direction
    const sortDirection = (this[`${columnName}SortDirection`] =
      this[`${columnName}SortDirection`] === 'asc' ? 'desc' : 'asc');

    const tableBody = this.element.querySelector('tbody');
    const rows = [...this.rowTargets];

    // remove all rows from the DOM
    rows.forEach((row) => row.remove());

    rows
      .sort((a, b) => {
        return compare(a, b, columnName, sortDirection);
      })
      .forEach((row) => tableBody.appendChild(row)); // append back to DOM
  }
}
