import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  /*
    Requires the user to download the 2fa backup codes in the wizard before moving on
  */
  downloadTarget: HTMLButtonElement;
  codesTarget: HTMLElement;
  nextTarget: HTMLAnchorElement;

  static targets = ['codes', 'next', 'download'];

  connect() {
    this.nextTarget.classList.add('disabled');
  }

  async download() {
    const codes = this.codesTarget.textContent;
    const file = new File([codes], 'my-backup-2fa-codes.txt');

    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = URL.createObjectURL(file);
    link.download = file.name;

    document.body.appendChild(link);
    link.click();

    this.nextTarget.classList.remove('disabled');

    setTimeout(() => {
      URL.revokeObjectURL(link.href);
      link.parentNode.removeChild(link);
    }, 0);
  }
}
