/// <reference types="stripe-v3" />
import { Controller } from '@hotwired/stimulus';

export type CreditCard = HTMLElement & {
  processCard: () => Promise<string | null>;
  toggleDisableCard: () => void;
};

export default class extends Controller {
  /*
    this controller is controlling a credit card form
  */

  stripeTokenTarget;
  stripeErrorTarget;
  cardTarget;
  card: stripe.elements.Element;
  stripe: stripe.Stripe;
  isDisabled;
  // @ts-expect-error 'element' is defined as an accessor in class 'Controller', but is overridden here in 'default' as an instance property.
  element: CreditCard;

  static targets = ['stripeToken', 'stripeError', 'card'];

  connect() {
    this.isDisabled = false;
    this.element.processCard = this.processCard.bind(this);
    this.element.toggleDisableCard = this.toggleDisableCard.bind(this);

    this.stripe = Stripe(this.stripeKey);
    const elements = this.stripe.elements();
    this.card = elements.create('card', {
      classes: {
        base: 'stripe-card',
      },
      style: {
        base: {
          color: '#808080',
          fontFamily: 'brandon-grotesque, Helvetica, Arial, sans-serif',
          '::placeholder': {
            color: '#CFD7E0',
          },
        },
        invalid: {
          color: 'f#d2d04',
          iconColor: '#fd2d04',
        },
      },
    });
    this.card.mount(this.cardTarget);
  }

  disconnect() {
    this.card.destroy();
  }

  get stripeToken() {
    return this.stripeTokenTarget.value;
  }

  get stripeKey() {
    return document.querySelector('meta[name="stripe-key"]').getAttribute('content');
  }

  toggleDisableCard() {
    this.isDisabled = !this.isDisabled;
    this.card.update({ disabled: this.isDisabled });
  }

  processCard() {
    this.hideStripeError();

    if (this.stripeToken.length) {
      // a stripe token was already generated for this user, so let the form submit
      return new Promise((resolve) => resolve(this.stripeToken));
    }

    return this.stripe.createToken(this.card).then((response) => {
      if (response.error) {
        this.showStripeError(response.error.message);
        return null;
      } else {
        const token = response.token;
        this.stripeTokenTarget.value = token.id;
        return token.id;
      }
    });
  }

  hideStripeError() {
    this.stripeErrorTarget.classList.add('hidden');
  }

  showStripeError(message) {
    this.stripeErrorTarget.classList.remove('hidden');
    this.stripeErrorTarget.innerHTML = message;
  }
}
