import { Controller } from '@hotwired/stimulus';

export type Tooltip = HTMLElement & {
  // show/hide conflicts with jquery for now, causes
  // infinite loop
  showTooltip: (title?: string) => void;
  hideTooltip: () => void;
};

export default class extends Controller {
  /*
    this controller for controlling a tooltip
  */

  tooltip;
  // @ts-expect-error 'element' is defined as an accessor in class 'Controller', but is overridden here in 'default' as an instance property.
  element: Tooltip;

  connect() {
    const trigger = this.data.get('trigger') || 'hover focus';
    // @ts-ignore
    this.tooltip = $(this.element).tooltip({ trigger });

    this.element.showTooltip = this.show.bind(this);
    this.element.hideTooltip = this.hide.bind(this);
  }

  show(title = null) {
    if (title) {
      // bootstrap changes 'title' attributes to 'data-original-title' when mounted
      this.element.dataset.originalTitle = title;
    }

    this.trigger('show');
  }

  hide() {
    this.trigger('hide');
  }

  trigger(action) {
    this.tooltip.tooltip(action);
  }
}
