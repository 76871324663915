import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  /*
    this controller is for the experience form
  */
  redirectInputTarget: HTMLInputElement;
  linksTargets: HTMLElement[];
  // @ts-expect-error 'element' is defined as an accessor in class 'Controller', but is overridden here in 'default' as an instance property.
  element: HTMLFormElement;

  static targets = ['redirectInput', 'links'];

  // when clicking 'save and redirect', it sets the value to the hidden
  // input, so we know to redirect in the controller
  setRedirect() {
    this.redirectInputTarget.value = 'new_experience';
    this.submit();
  }

  checkRemainingLinks() {
    const maxLinks = 5;
    if (this.linksTargets.length >= maxLinks) {
      alert(`A maximum of ${maxLinks} links are allowed.`);
      event.preventDefault();
    }
  }

  submit() {
    this.element.submit();
  }
}
