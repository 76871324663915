import { Controller } from '@hotwired/stimulus';
import * as print from 'print-js';

export default class extends Controller {
  url: string;

  async connect() {
    this.url = this.data.get('url');
  }

  print() {
    print(this.url);
  }
}
