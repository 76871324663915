import { Controller } from '@hotwired/stimulus';
import { Tooltip } from './tooltip_controller';

export default class extends Controller {
  /*
    this controller is for enabling copying to clipboard
  */

  tooltipTarget: Tooltip;

  static targets = ['tooltip'];

  async copy() {
    if (!navigator.clipboard) {
      this.showFallbackTooltip();
      return;
    }

    let textToCopy = this.data.get('text');
    const targetToCopyFrom = this.data.get('targetSelector');

    try {
      if (!textToCopy && targetToCopyFrom) {
        const target: HTMLInputElement = document.querySelector(targetToCopyFrom);
        textToCopy = target.value;
      }

      if (textToCopy) {
        await navigator.clipboard.writeText(textToCopy);
        this.setHideListener();
        this.tooltipTarget.showTooltip();
      }
    } catch (e) {
      this.showFallbackTooltip();
    }
  }

  showFallbackTooltip() {
    this.setHideListener();
    this.tooltipTarget.showTooltip('press Ctrl+C to copy');
  }

  setHideListener() {
    this.element.addEventListener(
      'mouseout',
      () => {
        this.tooltipTarget.hideTooltip();
      },
      { once: true }
    );
  }
}
