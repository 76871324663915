import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  /*
    this controller is for expanding/contracting content
  */

  contentTarget: HTMLElement;
  toggleTarget: HTMLButtonElement;
  // @ts-expect-error 'element' is defined as an accessor in class 'Controller', but is overridden here in 'default' as an instance property.
  element: HTMLElement;
  closedValue: boolean;

  static targets = ['content', 'toggle'];
  static values = { closed: Boolean };

  connect() {
    this.closed = this.contentTarget.hidden;
  }

  get closed() {
    return this.closedValue;
  }

  set closed(value) {
    this.closedValue = value;
    this.contentTarget.hidden = value;
  }

  toggle() {
    this.closed = !this.closed;

    const icon = this.toggleTarget.querySelector('i');
    icon.classList.toggle('fa-minus');
    icon.classList.toggle('fa-plus');

    this.toggleTarget.setAttribute('aria-label', this.closed ? 'show' : 'hide');
  }
}
