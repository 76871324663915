import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  /*
    this controller is for controlling the messages page
  */

  modalTabPaneTargets: HTMLDivElement[];
  modalTabTargets: HTMLAnchorElement[];
  bodyTarget: HTMLTextAreaElement;
  modalTarget: HTMLDivElement;

  static targets = ['modalTab', 'modalTabPane', 'body', 'modal', 'saveButton'];

  switchTab(e) {
    e.preventDefault();
    this.resetTabs();
    this.resetTabPanes();

    const selectedTab = e.target;
    selectedTab.closest('li a').classList.add('active');

    const paneId = selectedTab.getAttribute('href');
    const paneToActivate = this.element.querySelector(paneId);
    paneToActivate.classList.add('active');
  }

  openModal(e) {
    const button = e.target; // Button that triggered the modal
    const textarea = this.getHiddenTextArea(button);

    this.bodyTarget.value = textarea.value || '';
    this.data.set('input-origin', button.dataset.num);

    // triggers a refresh of the markdown - editor Stimulus controller
    this.modalTarget.dispatchEvent(new CustomEvent('refresh'));

    // @ts-ignore
    $(this.modalTarget).modal('show');
  }

  modalSave(e) {
    const target = this.data.get('input-origin'); // get the 'target' for where the val should be saved

    const textarea = this.getHiddenTextArea(document.querySelector(`[data-num="${target}"]`));
    textarea.value = this.bodyTarget.value;

    // @ts-ignore
    $(this.modalTarget).modal('hide');
  }

  closeModal() {
    // @ts-ignore
    $(this.modalTarget).modal('hide');
  }

  getHiddenTextArea(launchButton) {
    let textarea = launchButton.nextElementSibling;
    if (textarea.nodeName === 'DIV') {
      // it is wrapped in div if there is a validation error
      textarea = textarea.children[0];
    }
    return textarea;
  }

  resetTabs() {
    this.modalTabTargets.forEach((tab) => {
      tab.closest('li a').classList.remove('active');
    });
  }

  resetTabPanes() {
    this.modalTabPaneTargets.forEach((pane) => {
      pane.classList.remove('active');
    });
  }
}
