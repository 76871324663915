import { Controller } from '@hotwired/stimulus';
import Autocomplete from '@trevoreyre/autocomplete-js';

export default class extends Controller {
  /*
    this controller is for enabling copying to clipboard
  */

  autocomplete: Autocomplete;
  subjectValue: string;
  searchTarget: HTMLInputElement;
  // @ts-expect-error 'element' is defined as an accessor in class 'Controller', but is overridden here in 'default' as an instance property.ts(2610)
  element: HTMLFormElement;

  static targets = ['search'];
  static values = { subject: String };

  connect() {
    this.autocomplete = new Autocomplete(this.element, {
      search: this.search.bind(this),
      renderResult: this.renderResult.bind(this),
      getResultValue: (result) => result.value,
      onSubmit: this.onAutocompleteSubmit.bind(this),
      debounceTime: 150,
      baseClass: 'search-autocomplete',
    });
  }

  async search(input) {
    const [results, locations] = await Promise.all([this.getResults(input), this.getLocations(input)]);

    return [...results, ...locations];
  }

  // when someone actually clicks the submit button
  submit(event) {
    event.preventDefault();
    const value = this.searchTarget.value;
    this.onAutocompleteSubmit({ value, manualSubmission: true });
  }

  // when someone clicks an autocomplete result
  onAutocompleteSubmit({ value = undefined, isFirstLocation = undefined, manualSubmission = false } = {}) {
    if (!value) return;

    if (this.isPlaces) {
      // @ts-expect-error Property 'gaTrack' does not exist on type 'Window & typeof globalThis'.ts(2339)
      window.gaTrack('search', 'submitted', value);
    } else {
      // @ts-expect-error Property 'gaTrack' does not exist on type 'Window & typeof globalThis'.ts(2339)
      window.gaTrack('exp-search', 'submitted', value);
    }

    if (manualSubmission) {
      // @ts-expect-error Cannot find name 'Backbone'.ts(2304)
      Backbone.trigger(`${this.isPlaces ? 'place' : 'exp'}:search:submitted`, value);
    } else if (isFirstLocation !== undefined) {
      // only search if a location was selected. Results are links, and the page is navigating away anyway
      // @ts-expect-error Cannot find name 'Backbone'.ts(2304)
      Backbone.trigger(`${this.isPlaces ? 'place' : 'exp'}:location:submitted`, value);
    }
  }

  // used to render the dropdown. The autocomplete library doesn't support grouping
  // so we have to fake it a bit.
  renderResult(result, props) {
    const isResult = result.isFirstResult !== undefined;

    let header = '';
    if (result.isFirstResult) header = `<h5>${this.isPlaces ? 'Places' : 'Experiences'}</h5>`;
    if (result.isFirstLocation) header = '<h5>Near</h5>';

    const value = this.getFormattedValue(result.value);

    let body = '';
    if (isResult) {
      if (this.isPlaces) {
        props.class = `${props.class} search-autocomplete-place-result`;
        body = `
          <a href="${result.url}"><div class="image-wrapper"><img src="${result.image}"></div><span>${value}</span></a>
        `;
      } else {
        props.class = `${props.class} search-autocomplete-exp-result`;
        body = `<a href="${result.url}"><span>${value}</span></a>`;
      }
    } else {
      props.class = `${props.class} search-autocomplete-location-result`;
      body = `<p>${value}</p>`;
    }

    return `
      ${header}
      <li ${props}>
        ${body}
      </li>
    `;
  }

  // tries to find the match in the result and highlight it in bold
  getFormattedValue(value) {
    const query = this.searchTarget.value;
    const lowerCaseValue = value.toLowerCase();
    const lowerCaseQuery = query.toLowerCase();

    const index = lowerCaseValue.indexOf(lowerCaseQuery);
    if (index > -1) {
      return `${value.slice(0, index)}<strong>${value.slice(index, query.length)}</strong>${value.slice(
        index + query.length
      )}`;
    } else {
      return value;
    }
  }

  // fetches either places or experiences, depending on the page
  async getResults(input) {
    const query = this.isPlaces
      ? `{ autocomplete(query: \"${input}\") { name, slug, cached_image { service_path } } }`
      : `{ experienceAutocomplete(query: \"${input}\") { name, slug } }`;
    const csrfMeta = document.querySelector('meta[name="csrf-token"]');

    if (!csrfMeta) return;

    const csrfToken = csrfMeta.getAttribute('content');

    const response = await fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
      body: JSON.stringify({ query }),
    });
    const { data } = await response.json();

    if (this.isPlaces) {
      return data.autocomplete.map((item, index) => ({
        isFirstResult: index === 0,
        value: item.name,
        url: `/places/${item.slug}`,
        image:
          item.cached_image && item.cached_image.service_path
            ? // @ts-expect-error Property 'imgix' does not exist on type 'Window & typeof globalThis'.ts(2339)
              window.imgix.defaults().path(item.cached_image.service_path).fit('crop').width(50).height(50).to_url()
            : 'https://placehold.it/50x50',
      }));
    } else {
      return data.experienceAutocomplete.map((item, index) => ({
        isFirstResult: index === 0,
        value: item.name,
        url: `/experiences/${item.slug}`,
      }));
    }
  }

  // gets locations based on the query
  async getLocations(input) {
    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${input}.json?access_token=pk.eyJ1Ijoic2N0dGRhdnMiLCJhIjoiYmRkOTgxZjkzMGZkZjZlZWM5NWFkNGEzMWY2ZDA2ODAifQ.8h6vh7DzCj3M01fROU-_Dg`
    );
    const data = await response.json();
    return data.features.map((item, index) => ({
      isFirstLocation: index === 0,
      value: item.place_name,
    }));
  }

  disconnect() {
    this.autocomplete.destroy();
  }

  get isPlaces() {
    return this.subjectValue === 'places';
  }
}
