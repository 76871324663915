import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  /*
    this controller is for the form in the hero on the homepage
  */

  // @ts-expect-error 'element' is defined as an accessor in class 'Controller', but is overridden here in 'default' as an instance property.
  element: HTMLFormElement;

  static targets = [];

  typeChange(e) {
    const type = e.target.value;
    this.element.action = `/${type}`;
  }
}
