import { Controller } from '@hotwired/stimulus';
import { CreditCard } from './credit_card_controller';

export default class extends Controller {
  /*
    this controller is for controlling the booking form
  */

  groupTargets: HTMLElement[];
  numParticipantsInputTargets: HTMLInputElement[];
  numChaperonesInputTargets: HTMLInputElement[];
  hasCheckPaymentTarget: boolean;
  checkPaymentTarget: HTMLInputElement;
  creditCardFormTarget: CreditCard;
  hasCreditCardFormTarget: boolean;
  acceptedTerms: boolean;
  acceptTermsTarget: HTMLInputElement;
  confirmedBookingDetails: boolean;
  hasConfirmBookingDetailsTarget: boolean;
  confirmBookingDetailsTarget: HTMLInputElement;
  pricingOptionTarget: HTMLSelectElement;
  hasPricingOptionTarget: boolean;
  donationAmountTarget: HTMLInputElement;
  donationAmountSectionTarget: HTMLElement;
  hasDonationAmountSectionTarget: boolean;
  checkPaymentSectionTarget: HTMLElement;
  hasNumberOfParticipantsTarget: boolean;
  numberOfParticipantsTarget: HTMLElement;
  submitButtonTarget: HTMLInputElement;
  hasSubmitButtonTarget: boolean;
  priceTarget: HTMLElement;
  // @ts-expect-error 'element' is defined as an accessor in class 'Controller', but is overridden here in 'default' as an instance property.
  element: HTMLFormElement;
  remainingValue: number;

  static targets = [
    'group',
    'numParticipantsInput',
    'numChaperonesInput',
    'checkPayment',
    'creditCardForm',
    'acceptTerms',
    'pricingOption',
    'donationAmount',
    'checkPaymentSection',
    'price',
    'numberOfParticipants',
    'donationAmountSection',
    'submitButton',
    'confirmBookingDetails',
  ];
  static values = { remaining: Number };

  connect() {
    if (!this.hasPricingOptionTarget) return;

    // update page for pricing related values
    this.updatePrice();
    this.updateNumberOfParticipants();
    this.updatePricingOption();
  }

  async submit(event) {
    if (this.payingByCheck) {
      return true;
    }

    if (this.hasSubmitButtonTarget) {
      this.disableSubmitButton();
    }

    // not paying by card, continue to submit form
    // or already process card
    if (!this.hasCreditCardFormTarget) return true;

    event.preventDefault();
    const stripeToken = await this.creditCardFormTarget.processCard();

    if (stripeToken) {
      // succeeded
      this.element.submit();
    } else if (this.hasSubmitButtonTarget) {
      // failed
      this.enableSubmitButton();
    }
  }

  updatePrice() {
    const pricingOption = this.pricingOption;

    if (!pricingOption) return;

    const studentMultiplier = pricingOption.price_per === 'person' ? this.numParticipants : this.numGroups;
    const priceValue =
      pricingOption.kind === 'suggested_donation' ? this.donationAmount || pricingOption.price : pricingOption.price;
    const calculatedPrice = parseFloat(priceValue) * studentMultiplier;

    this.priceTarget.innerHTML = `$${calculatedPrice.toFixed(2)}`;

    if (!this.hasCheckPaymentTarget) return;

    // everything below here is for paying by card / check only

    // when less than 50 cents, just mark everything as paying by check
    // stripe requires sales of more than 50 cents to work
    if (calculatedPrice < 0.5) {
      this.checkPaymentSectionTarget.hidden = true;

      this.payingByCheck = true;
      this.creditCardFormTarget.hidden = true; // hide credit card form
    } else {
      this.creditCardFormTarget.hidden = false; // show credit card form
      this.checkPaymentSectionTarget.hidden = false;
      this.payingByCheck = false;
    }
  }

  get pricingOption() {
    if (!this.hasPricingOptionTarget) return;

    // @ts-ignore
    const pricingValues = (window.EpServerConfig && window.EpServerConfig.pricingValues) || [];
    const currentOption = this.pricingOptionTarget.value;

    return pricingValues.find((p) => {
      return p.id == parseInt(currentOption, 10);
    });
  }

  enableSubmitButton() {
    this.submitButtonTarget.removeAttribute('disabled');
  }

  disableSubmitButton() {
    this.submitButtonTarget.setAttribute('disabled', 'disabled');
  }

  updateForm() {
    const confirmedDetails = this.hasConfirmBookingDetailsTarget ? this.confirmedBookingDetails : true;
    if (this.acceptedTerms && confirmedDetails) {
      this.enableSubmitButton();
    } else {
      this.disableSubmitButton();
    }
  }

  toggleAcceptTerms(event) {
    this.acceptedTerms = event.target.checked;
    this.updateForm();
  }

  toggleConfirmBookingDetails(event) {
    this.confirmedBookingDetails = event.target.checked;
    this.updateForm();
  }

  toggleDisableCreditCard() {
    this.creditCardFormTarget.toggleDisableCard();
  }

  checkRemaining(event) {
    const remaining = this.remainingValue;
    if (this.numGroups === remaining) {
      alert(
        `This experience has availability for ${remaining} group(s). You've reached the limit. Please click 'Request Booking' below to complete your booking.`
      );
      event.preventDefault();
    }
  }

  checkMinimum(event) {
    if (this.groupTargets.length === 1) {
      alert('You must have at least one group for a booking.');
      event.preventDefault();
    }
  }

  updateNumberOfParticipants() {
    // either no payment options or editing the booking
    if (!this.hasNumberOfParticipantsTarget) return;

    const numberOfKids = this.numParticipantsInputTargets.reduce((acc, el) => {
      return acc + parseFloat(el.value || '0');
    }, 0);
    const numberOfChaperones = this.numChaperonesInputTargets.reduce((acc, el) => {
      return acc + parseFloat(el.value || '0');
    }, 0);
    const studentFormattedString = numberOfKids === 1 ? ' for 1 student ' : ` for ${numberOfKids} students `;
    const chaperonesFormattedString =
      numberOfChaperones === 1 ? 'for 1 chaperone ' : `for ${numberOfChaperones} chaperones `;
    this.numberOfParticipantsTarget.textContent = `${studentFormattedString} and ${chaperonesFormattedString}`;
  }

  updatePricingOption() {
    // @ts-ignore
    const suggestedDonationOptions = (window.EpServerConfig && window.EpServerConfig.pricingOptions) || [];

    if (!this.hasDonationAmountSectionTarget) return;

    if (suggestedDonationOptions.indexOf(this.pricingOptionTarget.value) > -1) {
      this.donationAmountSectionTarget.hidden = false;
      // get the selected pricing option text, and try to parse out the price from it
      const selectedOption = this.pricingOptionTarget.selectedOptions[0];
      const priceMatch = selectedOption && selectedOption.textContent.match(/(\d+\.\d{1,2})/g);
      const price = priceMatch && priceMatch[0];
      this.donationAmountTarget.value = price || '0';
    } else {
      this.donationAmountSectionTarget.hidden = true;
      this.donationAmountTarget.value = null;
    }
  }

  get payingByCheck() {
    return this.hasCheckPaymentTarget && this.checkPaymentTarget.checked;
  }

  set payingByCheck(value) {
    if (this.hasCheckPaymentTarget) {
      this.checkPaymentTarget.checked = !!value;
    }
  }

  get donationAmount() {
    if (this.hasDonationAmountSectionTarget) {
      return this.donationAmountTarget.value;
    }
  }

  get numGroups() {
    return this.groupTargets.length;
  }

  get numParticipants() {
    // @ts-ignore
    const { charge_chaperones = false, max_free_chaperones = 0 } = window.EpServerConfig?.experience || {};
    const freeChaperones = charge_chaperones ? max_free_chaperones : 0;

    const participants = this.numParticipantsInputTargets.reduce((acc, el) => acc + parseFloat(el.value || '0'), 0);
    const chaperones = this.numChaperonesInputTargets
      .map((el) => Math.max(0, parseFloat(el.value || '0') - freeChaperones)) // make sure 0 is the lowest number possible with Math.max
      .reduce((acc, num) => acc + num, 0);
    return charge_chaperones ? participants + chaperones : participants;
  }
}
