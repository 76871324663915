import { Controller } from '@hotwired/stimulus';
import Choices from 'choices.js';

import 'choices.js/public/assets/styles/choices.css';

export default class extends Controller {
  /*
    this controller is hooking up bootstrap multiselect
  */

  // @ts-expect-error 'element' is defined as an accessor in class 'Controller', but is overridden here in 'default' as an instance property.
  element: HTMLSelectElement;

  connect() {
    /*
      class names are documented here: https://github.com/jshjohnson/Choices#setup
      we have our css overrides in multiselect.scss
    */
    new Choices(this.element);
  }
}
