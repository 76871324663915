import { Controller } from '@hotwired/stimulus';

const stripHtml = (html) => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

const truncate = (string, length) => {
  if (string.length <= length) return string;

  return string.substring(0, length - 1) + '\u2026';
};

export default class extends Controller {
  /*
    this controller is for markdown text to HTML
  */

  static targets = [];

  connect() {
    const data = this.data.get('data');
    const shouldStripHtml = this.data.get('strip-html');
    const truncateLength = this.data.get('truncate');
    // @ts-expect-error Cannot find name 'Markdown'
    const markdownConverter = new Markdown.Converter();
    let converted = markdownConverter.makeHtml(data);

    if (shouldStripHtml) {
      converted = stripHtml(converted);
    }

    if (truncateLength) {
      converted = truncate(converted, truncateLength);
    }

    this.element.innerHTML = converted;
  }
}
