import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  /*
    this controller is for enabling a markdown editor textarea
  */

  buttonBarTarget: HTMLElement;
  hasPreviewTarget: boolean;
  previewTarget: HTMLElement;
  inputTarget: HTMLTextAreaElement;
  editor;

  static targets = ['buttonBar', 'preview', 'input'];

  connect() {
    const attribute = this.data.get('attribute');

    this.buttonBarTarget.setAttribute('id', `wmd-button-bar-${attribute}`);
    this.inputTarget.setAttribute('id', `wmd-input-${attribute}`);
    this.inputTarget.classList.add('wmd-input');

    if (this.hasPreviewTarget) {
      this.previewTarget.setAttribute('id', `wmd-preview-${attribute}`);
    }

    // @ts-ignore
    const markdownConverter = new Markdown.Converter();
    // @ts-ignore
    Markdown.Extra.init(markdownConverter);
    const help = {
      handler: () => {
        window.open('http://daringfireball.net/projects/markdown/syntax');
        return false;
      },
      title: 'Markdown Editing Help',
    };
    // @ts-ignore
    this.editor = new Markdown.Editor(markdownConverter, `-${attribute}`, help);
    this.editor.run();
  }

  refresh() {
    this.editor.refreshPreview();
  }
}
