import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  /*
    this controller is used for the registration form, where you must
    accept the terms and conditions before signing up
  */
  submitTarget;

  static targets = ['submit'];

  toggleAcceptTerms(event) {
    const accepted = event.target.checked;
    if (accepted) {
      this.submitTarget.removeAttribute('disabled');
    } else {
      this.submitTarget.setAttribute('disabled', 'disabled');
    }
  }
}
