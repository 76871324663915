import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  /*
    this controller is for delete buttons, where we want to
    get a confirmation from the user before we actually delete
    the record
  */
  recordType;
  message;

  initialize() {
    this.recordType = this.data.get('type') || 'item';
    this.message = this.data.get('message') || `Are you sure you want to permanently delete this ${this.recordType}?`;
  }

  confirm(event) {
    if (!confirm(this.message)) {
      event.preventDefault();
    }
  }
}
