import { Controller } from '@hotwired/stimulus';

interface Place {
  lat: number;
  long: number;
  kind: string;
  name: string;
  slug: string;
}

export default class extends Controller {
  mapTarget: HTMLElement;
  placesValue: Place[];
  typeValue: 'static' | 'interactive';

  static targets = ['map'];
  static values = {
    places: Array,
    type: String,
  };

  async connect() {
    const places = this.placesValue;
    // static map is on place page
    const isInteractive = this.typeValue === 'interactive';

    // set up map
    // @ts-expect-error Cannot find name 'mapboxgl'.ts(2304)
    mapboxgl.accessToken =
      'pk.eyJ1Ijoic2N0dGRhdnMiLCJhIjoiYmRkOTgxZjkzMGZkZjZlZWM5NWFkNGEzMWY2ZDA2ODAifQ.8h6vh7DzCj3M01fROU-_Dg';

    // @ts-expect-error Cannot find name 'mapboxgl'.ts(2304)
    const map = new mapboxgl.Map({
      container: this.mapTarget, // container ID
      center: places.length > 1 ? [0, 0] : [places[0].long, places[0].lat],
      minZoom: places.length === 1 ? 13 : 5,
      style: 'mapbox://styles/scttdavs/ckbb4p6zg0b041iqrbebddx77', // style URL
      interactive: isInteractive,
    });

    map.on('load', () => {
      // @ts-expect-error Cannot find name 'mapboxgl'.ts(2304)
      const bounds = new mapboxgl.LngLatBounds();

      places.forEach((place) => {
        // just add default markers
        // @ts-expect-error Cannot find name 'mapboxgl'.ts(2304)
        new mapboxgl.Marker({ color: '#374E6D' }).setLngLat([place.long, place.lat]).addTo(map);

        if (isInteractive) {
          bounds.extend([place.long, place.lat]);
        }
      });

      if (isInteractive) {
        map.fitBounds(bounds, { padding: 100, maxZoom: 12 });
      }
    });
  }
}
