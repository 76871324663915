import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  /*
    this controller is for the booking calendar 'month' page
  */

  toggleShowAll(e) {
    const searchParams = this.getShowAllSearchParams(e.target.checked);
    this.updateUrl(searchParams);
  }

  toggleBookingOnly(e) {
    const searchParams = this.getBookingsOnlySearchParams(e.target.checked);
    this.updateUrl(searchParams);
  }

  updateExperience(e) {
    const newOffering = e.target.value;
    const numberRegex = /([0-9])+/;
    const offeringRegex = /offering=([0-9])+/;
    let searchParams = this.getBookingsOnlySearchParams(false);

    if (searchParams.match(offeringRegex)) {
      searchParams = this.updateParams(offeringRegex, numberRegex, newOffering, searchParams);
    } else {
      searchParams = this.addParam('offering', newOffering, searchParams);
    }

    this.updateUrl(searchParams);
  }

  getShowAllSearchParams(newValue) {
    const showAllRegex = /show_all=/;
    const valueRegex = /true|false/;
    let searchParams;
    if (window.location.search.match(showAllRegex)) {
      searchParams = this.updateParams(showAllRegex, valueRegex, newValue);
    } else {
      if (newValue == false) {
        return window.location.search;
      }
      searchParams = this.addParam('show_all', newValue);
    }

    return searchParams;
  }

  getBookingsOnlySearchParams(newValue) {
    const bookingsOnlyRegex = /bookings_only=/;
    const valueRegex = /true|false/;
    let searchParams;
    if (window.location.search.match(bookingsOnlyRegex)) {
      searchParams = this.updateParams(bookingsOnlyRegex, valueRegex, newValue);
    } else {
      if (newValue == false) {
        return window.location.search;
      }
      searchParams = this.addParam('bookings_only', newValue);
    }
    return searchParams;
  }

  updateUrl(searchParams) {
    window.location.search = searchParams;
  }

  addParam(name, newValue, searchParams = window.location.search) {
    const value = `${name}=${newValue}`;
    const paramValue = searchParams.indexOf('?') > -1 ? `&${value}` : `?${value}`;

    return searchParams + paramValue;
  }

  updateParams(matchRegex, valueRegex, newValue, searchParams = window.location.search) {
    const newSearch = searchParams.split('&').map((q) => {
      if (q.match(matchRegex)) {
        return q.replace(valueRegex, newValue);
      } else {
        return q;
      }
    });

    return newSearch.join('&');
  }
}
