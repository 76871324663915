import { Controller } from '@hotwired/stimulus';
// used just for types
import { PDFWorker, getDocument } from 'pdfjs-dist/types/src/pdf.js';

type PDFjs = {
  getDocument: typeof getDocument;
  GlobalWorkerOptions: {
    workerPort: PDFWorker;
  };
};

// these are defined globally. It's a pain, but I _think_ we can get this working
// with direct imports once I can get Webpacker 5
declare let pdfjsLib: PDFjs;
declare let PdfjsWorker: typeof PDFWorker;

export default class extends Controller {
  canvasTarget;

  static targets = ['canvas'];

  async connect() {
    if (typeof window !== 'undefined' && 'Worker' in window) {
      pdfjsLib.GlobalWorkerOptions.workerPort = new PdfjsWorker();
    }

    const url = this.data.get('url');
    const canvas = this.canvasTarget;
    const outputScale = window.devicePixelRatio || 1;

    pdfjsLib.getDocument(url).promise.then(
      async (pdf) => {
        const page = await pdf.getPage(1);

        const scale = 1.5;
        const viewport = page.getViewport({ scale });

        // Prepare canvas using PDF page dimensions
        const context = canvas.getContext('2d');
        canvas.height = Math.floor(viewport.height * outputScale);
        canvas.width = Math.floor(viewport.width * outputScale);
        const transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

        // Render PDF page into canvas context
        const renderContext = {
          canvasContext: context,
          transform,
          viewport,
        };
        page.render(renderContext).promise.then(() => {
          // render done
        });
      },
      (reason) => {
        // @ts-ignore
        Rollbar.error(reason);
      }
    );
  }
}
